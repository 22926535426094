.rev-btn--transparent {
  background: transparent !important;
  border: 2px solid $color__brand-yellow !important;
  color: $color__brand-yellow !important;
  &:hover {
    color: $color__brand-yellow--hover;
  }
}
.rev_slider {
  .slotholder:before {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    content: " ";
    z-index: 99999;
  }
}
.rev_slider_wrapper {
  @media only screen and (max-width : 768px) {
    height:  400px !important;

  }
  @media only screen and (max-width : 414px) {
    height:  300px !important;
  }
}
.tp-fullwidth-forcer {
  @media only screen and (max-width : 768px) {
    height: 400px !important;
  }
  @media only screen and (max-width : 414px) {
    height: 300px !important;
  }
}