.stuning-header-inner {
  > .row {
    z-index: 9999;
  }
  &:before {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    content: " ";
    z-index: 1;
  }
}