.dfd-portfolio-loop .project {
  .dfd-read-share {
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
    border: none;
    //color: $color__brand-yellow;
    .read-more-wrap {
      float: none;
      .more-button.slide-up {
        color: $color__brand-yellow;
        &:hover {
          text-shadow: 0 0 #fff, 0 16px $color__brand-yellow--hover;
        }
      }
    }
  }
  .dfd-folio-heading-wrap {
    padding-bottom: 40px !important;
  }
  .entry-thumb{
    .dfd-folio-icons-wrap {
      bottom: 50px;
    }
  }
}