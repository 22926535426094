.header-style-2.header-layout-boxed.sticky-header-enabled:not(.small) .header-col-fluid {
  display: flex !important;
  width: 100%;
  justify-content: flex-end;
  margin-top: 100px !important;
  @media only screen and (max-width: 1100px) {
    display: none !important;
    margin-top: 0 !important;
  }
}

#header-container:not(.small) #header .header-wrap > .row:last-child .columns.header-main-panel .header-col-right .header-icons-wrapper,
#header-container:not(.small) #header .header-wrap > .row:last-child .columns.header-main-panel .header-col-left,
#header-container:not(.small):not(.logo-position-right) #header .header-wrap > .row:last-child .columns.header-main-panel .header-col-right {
  @media only screen and (max-width: 1100px) {
    height: auto !important;
    line-height: unset !important;
  }
}

#header-container:not(.small) #header .header-wrap > .row:last-child .columns.header-main-panel .header-col-right .header-icons-wrapper > * {
  @media only screen and (max-width: 1100px) {
    top: 0 !important;
  }
}

#mobile-menu {
  top: 100% !important;
}

//#header-container:not(.small) #header .header-wrap > .row:last-child .columns.header-main-panel .header-col-left .mobile-logo * {
//  @media only screen and (max-width: 1100px) {
//    position: absolute !important;
//    top: -50px;
//  }
//
//}

#header-container:not(.small) #header .header-wrap > .row:last-child .columns.header-main-panel .header-col-left .mobile-logo {
  @media only screen and (max-width: 1100px) {
    height: auto !important;
  }
  @media only screen and (max-width: 1100px) {
    img {
      max-height: 80px !important;
    }
  }
  //@media only screen and (max-width: 768px) {
  //  img {
  //    max-height: 80px !important;
  //  }
  //}
}

#header-container:not(.small) #header .header-wrap > .row:last-child .columns.header-main-panel .header-col-left {
  //padding: 0 !important;
  padding-bottom: 0 !important;
}

.header-style-2.header-layout-boxed.sticky-header-enabled:not(.small) .header-top-panel {
  border: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 60px;
  @media only screen and (max-width: 1100px) {
    top: 0;
    margin: 0 auto;
    .top-info {
      padding-right: 0;
    }
  }
}

.sidr-top {
  //display: none;
}

#header-container .header-info-panel .soc-icons a:before {
  font-size: 18px;
}

.header-style-2.header-layout-boxed.sticky-header-enabled .header-col-fluid {
  margin: 0 !important;
  padding: 0 !important;
}

.header-style-2.header-layout-boxed.sticky-header-enabled.small .header-col-right {
  display: none;
}

.header-style-2.header-layout-boxed.sticky-header-enabled .header-col-fluid .nav-menu {
  padding: 0;
}

.header-info-panel {
  display: flex;
  justify-content: flex-end;
}

.header-info-panel .soc-icons a:before {
  color: $color__brand-yellow !important;
  &:hover {
    color: $color__brand-yellow--hover !important;
  }
}