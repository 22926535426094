.text-white {
  p {
    color: #fff;
  }
}

.heading-white {
  h1, h2, h3, h4, h5 {
    color: #fff;
  }
}