.our-work {
  .vc_gitem-post-data-source-post_excerpt {
    p {
      color: #fff !important;
    }
  }

  .dfd-icon-down_right {
    display: none;
  }

  .vc_gitem-post-data-source-post_title {
    color: #fff;
    font-size: 20px;
  }

  .portfolio-grid-item__title {
    h5 {
      color: #fff;
      font-size: 18px;
      line-height: 24px;
    }
  }

  .portfolio-grid-item__desc {
    p {
      text-align: center;
    }
  }
}