.wpcf7-form  {
  .row & .row {
    //text-align: center !important;
  }
  .wpcf7-submit {
    margin-top: 50px !important;
      border: 1px solid $color__brand-yellow !important;
      width: auto;
      color: $color__brand-yellow !important;
      text-transform: uppercase !important;
      display: inline-block !important;
      width: auto !important;
      margin: 20px auto !important;
      //text-align: center !important;
      padding-left: 30px !important;
      padding-right: 30px !important;

      &:hover {
        color: $color__brand-yellow--hover !important;
      }
  }
  .wpcf7-response-output {
    color: #fff !important;
  }
}
.page-id-115 {
  .wpcf7-form {
    .wpcf7-submit {
      color: #333 !important;
    }
  }
}