.border-circle {
  border-radius: 50% !important;
  //box-sizing: border-box !important;
}

.nowrap {
  white-space: nowrap;
}

.entry-thumb {
  .title-wrap .portfolio-entry-link {
    //display: none;
    visibility: hidden;
    transition: top .3s ease-in-out;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    font-style: italic;
    //padding-top: 50px;

    &:hover {
      color: $color__brand-yellow--hover;
    }
  }

  &:hover {
    .title-wrap .portfolio-entry-link {
      visibility: visible;
      display: inline-block;
      top: calc(50% + 10px);
      width: auto;
    }
  }
}

.hidden {
  display: none;
}

.row-square {
  border-radius: 50% !important;
}
.portfolio-entry-hover:before {
  border: 1px solid rgba(#fff, .1);
  border-radius: 50%;
  content: '';
  width: 100%;
  height: 100%;
  display: block;
}
.divided-section {
  @media only screen and (max-width: 800px) {
    > .wpb_row {
      display: flex;
      flex-direction: column;
      > * {
        width: 100%;
      }
    }
  }
  .row-square {
    @media only screen and (max-width: 1024px) {
      border: none !important;
    }
    @media only screen and (max-width: 800px) {
      height: auto !important;
      .twelve {
        padding: 0 !important;
        .ult-spacer {
          display: none !important;
        }
      }
    }
  }
}

.row form .row {
  margin: 0;
}

.mobile-columns-switch {
  @media only screen and (max-width: 800px) {
    > .wpb_row {
      display: flex;
      flex-direction: column-reverse;
      > * {
        width: 100%;
      }
    }
  }
}

h3.uvc-main-heading {
  @media only screen and (max-width: 414px) {
    font-size: 24px !important;
    line-height: 30px !important;
    margin-bottom: 0 !important;
  }
}
.page-id-115 {
  .block-icon {
    @media only screen and (max-width : 320px) {
      width: 50px !important;
      height: 50px !important;
    }
  }
  .block-content {
    @media only screen and (max-width : 320px) {
      margin-left: 40px !important;
    }
  }
}
div.page-title-inner .breadcrumbs {
  font-weight: bold !important;
  font-size: 16px !important;
}
.page-id-16758 { //TODO: /our-work
  .stuning-header-inner:before {
    background-color: rgba(0, 0, 0, 0.6);
  }
}
@media only screen and (max-width : 414px) {
  .dfd-stuning-header-bg-container {
    background-attachment: inherit !important;
  }
  div.page-title-inner {
    min-height: 300px !important;
    height: 300px !important;
  }

}
